import React from 'react';

import './footer.css';

const Footer = () => {
  return (
    <>
      <div className="footer__ocean">
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
            
              <div>
                <div className="copyright-block">
                  <p className="copyright">
                    <small className="block color">&copy; 2022 Туристичне агентство "Чемоданчик" | Туристическое агентство "Чемоданчик"<br />Всі права захищені. </small>
                    <small className="block color">Створено в <a target="_blank" rel="noopener noreferrer" href="https://www.spacedev.team/">www.spacedev.team</a> </small>
                  </p>
                  <div className="copyright-icons">
                    <ul className="gtco-social-icons">
                      <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/chemodanchik_kiev/"><i className="icon-instagram"></i></a></li>
                      <li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/chemodanchik.kiev/"><i className="icon-facebook"></i></a></li>
                      <li><a rel="noreferrer" target="_blank" href="https://invite.viber.com/?g2=AQBzF3HAGo6whEmnEUU7qPkoez6djRaSLwZjsPX0KuDNgjLqZ3kMoMRNL94k6JIk"><i className="fab fa-viber"></i></a></li>
                      <li><a rel="noreferrer" target="_blank" href="https://t.me/chemodanchikkiev"><i className="fab fa-telegram-plane"></i></a></li>
                      <li><a rel="noreferrer" target="_blank" href="https://g.page/chemodanchik-kiev/review?rc"><i className="fab fa-google"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
      {/*<div className="arrow-up">
        <a href="#"><i className="fas fa-arrow-up"></i></a>
      </div>*/}
    </>
  )
}

export default Footer;
