import React, { useState, useEffect } from 'react';
import axios from "axios";
//import { useHistory } from 'react-router-dom'
import PodborkaPage from '..//PodborkaPage/PodborkaPage'
import { Splide, SplideSlide } from '@splidejs/react-splide';

import './podborki.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import img1 from '../../images/podborki/podborki1.jpg';
import img2 from '../../images/podborki/podborki2.jpg';
import img3 from '../../images/podborki/podborki3.jpg';
import img4 from '../../images/podborki/podborki5.jpg';
import img5 from '../../images/podborki/podborki6.jpg';

const uaKeys = {
  'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
  'є': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'і': 'i',
  'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
  'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
  'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
  'щ': 'shch', 'и': 'y', 'е': 'e', 'ю': 'u', 'я': 'ya',
  'ї': 'i', 'ь': '', 'й': 'yi', '!': 'exc', '?': '@'
}

function transliterate(word) {
  return word.replace(/ /g, '_').split("").map((letter) => {
      const lowLetter = letter.toLowerCase();
      const en = uaKeys[lowLetter] ?? letter;
      return lowLetter === letter ? en : en.toUpperCase();
  }).join("");
}

const Podborki = () => {

  const [podborki, setPodborki] = useState([]);
  //const [popupVisible, setPopupVisible] = useState(false);
  const [currentActive, setCurrentActive] = useState({});

  const photo = [img1, img2, img3, img4, img5];

  //const history = useHistory();

  /*const openModal = (active) => {
    setCurrentActive(active);
    setPopupVisible(true);
  }*/

  useEffect(() => {
    if(podborki.length>0) {
      return;
    } else {
      axios
      .get('https://api.chemodanchik.online/collections')
      .then(response => { setPodborki(response.data.reverse()) });
    }
  }, []);

  return (
    <>
      <section id="podborki" className="podborki select-text">
        <div >
          <h1 className="podborki__title">Чемоданчик рекомендує:</h1>
        </div>
        <div className="podborki__blocks">
        {
          podborki.length > 0 && (
            <Splide
              options={ {
                type: 'loop',
                fixedHeight: '500px',
                perPage: 3,
                perMove: 1,
                autoplay: true,
                breakpoints: {
                  1200: {
                    perPage: 2,
                  },
                  700: {
                    perPage: 1,
                  },
                },
              }}
            >
              {podborki.map((podborka, i) => (
                <SplideSlide>
                  <div 
                    className="podborki__block"
                  >
                    <img 
                      src={podborka.img}
                      alt=''
                      className='podborka-image'
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        //history.push(`/tours/${transliterate(podborka.description)}__`);
                        window.open(`/tours/${transliterate(podborka.description)}__`)
                      }}
                    />
                    <div className="info-price">{podborka.price}</div>
                    <div className="info-date">{podborka.date}</div>
                    <div 
                      className="podborki__content"
                      onClick={() => {
                        //history.push(`/tours/${transliterate(podborka.description)}__`);
                        window.open(`/tours/${transliterate(podborka.description)}__`)
                      }}
                    >
                      <div className="podborki__info-text">
                        <p className="podborki__info-date">{podborka.description}</p>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          )}
          </div>
          <div className="podborki__info">
            <p>Ще не знаєте, куди душа бажає, а омріяна відпустка сниться ночами?<br />Не пропустіть свій ідеальний відпочинок - мерщій підписуйтеся на наш Viber-канал, де щодня публікуємо найгарячіші підбірки</p>
            <div className="btn btn-podborki">
              <i className="fab fa-viber"></i>
              <a href="https://invite.viber.com/?g2=AQBzF3HAGo6whEmnEUU7qPkoez6djRaSLwZjsPX0KuDNgjLqZ3kMoMRNL94k6JIk">
                Подивитися в Viber
              </a>
            </div>
          </div>
          {podborki.length>0 && typeof currentActive.list !== "undefined" && (
            <>
            <PodborkaPage currentActive={currentActive}
            />
          </>
          )}
      </section>
    </>
 )
}
export default Podborki;