import React, { useState, useEffect } from 'react';
import Footer from '..//Footer/Footer.jsx';
import { VectorMap } from '@south-paw/react-vector-maps';
import world from '..//..//data/world.json';
import axios from "axios";
import Modal from '..//Modal/Modal.jsx';
import Navigation from '..//Navigation/Navigation.jsx';

import './map.css';

const Map = () => {

  const [countries, setCountries] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentActive, setCurrentActive] = useState({})

  useEffect(() => {
    if(countries.length > 0) {
      return 
    } else {
      axios
      .get('https://api.chemodanchik.online/data/countries')
      .then(response => {setCountries(response.data)});
    }
  });

  const openModal = () => {
    setModalIsOpen(true)
  }

  const onClick = ({ target }) => {
    const name = target.attributes.id.textContent;
    countries.forEach(country => {
      if ( name === country.country.toLowerCase() ) {
        setCurrentActive(country);
        console.log(country)
      }
    });
    openModal();
  }

  return (
    <>
      <Navigation />
      <div className="container-map">
        <VectorMap {...world} layerProps={{ onClick }} />
      </div>
      <Modal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        {...currentActive}
      />

      <Footer />
    </>
  )
}

export default Map;