import React from 'react';

import './form.css';

const Form = () => {
  return (
    <>
      <section className="form">
        <p><span>Хочете підібрати тур швидко? Залишайте заявку - і "Чемоданний" настрій гарантовано!</span></p>
        <a className="btn btn-primary" href="contact">Залишити заявку</a>
      </section>
    </>
  )
}

export default Form;
