import React from 'react';
import Navigation from '../components/Navigation/Navigation'
import header from '..//images/header.jpg';

const Header = ( {setLoaded} ) => {

  console.log(setLoaded)

  const imageLoaded = () => {
    setLoaded(true);
  }

  return (
    <>
      <Navigation />

      <header id="gtco-header" className="gtco-cover gtco-cover-md gtco-cover-header" role="banner" >
        <img
            alt=""
            src={header}
            className="header__img"
            onLoad={() => imageLoaded()}

        />
        <div className="overlay"/>
          <div className="gtco-container">
            <div className="row">
              <div className="col-md-12 col-md-offset-0 text-left">
                <div className="row row-mt-15em">
                  <div className="col-md-7 mt-text animate-box" data-animate-effect="fadeInUp">
                    <h1>Мандруйте разом із<br/>Чемоданчиком!</h1>
                    <p className="header-text">
                      {"Туристичне агентство “Чемоданчик” - експерти з вашого ідеального відпочинку!"}
                      <br/><br/>
                      {"Допоможемо підібрати найкращі пакетні тури в Єгипет, Туреччину, Грецію, Кіпр, Іспанію, ОАЕ, Чорногорію, Грузію, Мальдіви, Домінікану, Шрі-Ланку, Занзібар, Кубу, Мексику та на понад 40 інших напрямків по Європі та всьому світу з Києва, Львова, Одеси, Харкова, Дніпра, Запоріжжя."}
                      <br/>{"А якщо Вас зацікавить індивідуальний авторський тур, наприклад, в Буркіна-Фасо, то до найдрібніших подробиць все з'ясуємо і організуємо відпочинок вашої мрії!"}</p>
                  </div>{/*
                  <div className="col-md-4 col-md-push-1 animate-box" data-animate-effect="fadeInRight">
                    <div className="form-wrap">
                      <div className="tab">
                        <div className="tab-content">
                          <div className="tab-content-inner active" data-content="signup">
                            <h3>Забронируйте поездку</h3>
                            <form action="#">
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <label className="text-form" htmlFor="fullname">Ваше имя</label>
                                  <input type="text" id="fullname" className="form-control input-color" style={{color: "rgba(255, 255, 255, 0.603)"}} />
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <label className="text-form" htmlFor="destination">Направление</label>
                                  <select name="#" id="destination" className="form-control input-color" style={{color: "rgba(255, 255, 255, 0.603)"}}>
                                    <option style={{background: "white", color: "grey"}} value="">Philippines</option>
                                    <option style={{background: "white", color: "grey"}} value="">USA</option>
                                    <option style={{background: "white", color: "grey"}} value="">Australia</option>
                                    <option style={{background: "white", color: "grey"}} value="">Singapore</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <label className="text-form" htmlFor="date-start">Дата отправления</label>
                                  <input type="date" id="date-start" className="form-control input-color" style={{color: "rgba(255, 255, 255, 0.603)"}} />
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <label className="text-form" htmlFor="date-start">Дата возвращения</label>
                                  <input type="date" id="date-start" className="form-control input-color" style={{color: "rgba(255, 255, 255, 0.603)"}} />
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <input type="submit" className="btn btn-primary btn-block" value="Отправить" />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
      </header>

    </>
  )
}

export default Header;
