import React from 'react';
import { Redirect } from 'react-router-dom';
import Page from '..//Page.jsx';
import NewsPage from '..//components/News/NewsPage.jsx';
import Map from '..//components/Map/Map.jsx';
import Contact from '..//components/Contact';
import PodborkaPage from '..//components/PodborkaPage/PodborkaPage'
import Error from '../components/Error/Error';
import News from '../components/NewsItem/News'

export const routes = [
  {
    path: '/news',
    component: NewsPage,
    exact: true,
  },
  {
    path: '/news/:title',
    component: News,
    render: ({match}) => (
      <News match={ match } />
    ),
    exact: true,
  },
  {
    path: '/404',
    component: Error,
    exact: true,
  },
  {
    path: '/map',
    component: Map,
    exact: true,
  },
  {
    path: '/contact',
    component: Contact,
    exact: true,
  },
  {
    path: '/tours/:description',
    component: PodborkaPage,
    render: props => (
      <PodborkaPage props={ props } />
    ),
    exact: true,
  },
  {
    path: '/',
    component: Page,
    exact: true,
  },
  {
    component: () => <Redirect to="/" />,
  },
]