import React, { useState } from 'react';
import Team from '../Team/Team'

import './about.css';

const About = () => {

  const [mobile, setMobile] = useState(true);


  return (
    <>
      <section className="about">
        <div className="about__text">
          <h4><strong>ЧОМУ ВАМ ВАРТО ЗВЕРНУТИСЯ САМЕ ДО НАС?</strong></h4>
          <p>Впевнені, для вас не буде новиною, що на ринку України працює велика кількість туристичних агентств. Так чому ж <strong>обираючи кому довірити організацію свого відпочинку вам варто звернутися саме до нас? </strong></p>
          <ul>
            <li><strong>Турбота</strong> – ми не позиціонуємо себе з «туристичними супермаркетами» і індивідуально підходимо до кожного туриста - концентруючись на найдрібніших персональних потребах, супроводжуючи 24/7 з моменту звернення до повернення з відпочинку в кінцевому результаті отримуємо задоволених постійних клієнтів</li>
            <li><strong>Досвід</strong> – успішно працюємо в сфері туризму з 2012 року, самі часто подорожуємо в різні куточки світу, щоб вдосконалити знання про всі тонкощі тих місць, в яких пропонуємо відпочити вам</li>
            <li><strong>Безпечність</strong> - співпрацюємо з усіма надійними туроператорами та системами онлайн-бронювання готелів і авіаквитків</li>
            <li><strong>Надійність</strong> – вистояли в надскладний сезон затримок рейсів 2018-го та під час закриття кордонів 2020-го</li>
            <li><strong>Прозорість</strong> – ми ФОП Третяк Анна Вікторівна (РНОКПП 3408405784). Рахунок відкрито в банку АТ КБ "ПРИВАТБАНК" у форматі IBAN: UA283052990000026006026715042</li>
          </ul>
          <p>Якщо ви поважаєте свій час, кошти та гарний настрій, якщо хочете безтурботного відпочинку за оптимальною ціною, якщо цінуєте якісний сервіс обслуговування</p>
          <p><strong>Мандруйте разом із Чемоданчиком!</strong></p>
        </div>
        <Team mobile={mobile}/>
        <div className="about__block">
          <iframe 
            title="Chemodanchik"
            src={ "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fchemodanchik.kiev&tabs=timeline&width=300&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" }
            width="300" height="600"
            style={{ border: "none", overflow: "hidden" }} scrolling="no"
            frameBorder="0" allowFullScreen={true} 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </section>
    </>
  )
}

export default About;
