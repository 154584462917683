import React, { useState, useEffect } from 'react';
import Footer from '../Footer/Footer.jsx';
import axios from 'axios';
import NewsItem from '..//NewsItem/NewsItem.jsx';
import Navigation from '..//Navigation/Navigation.jsx';
import Form from '..//Form/Form';

import './news-page.css';

const NewsPage = () => {

  const [news, setNews] = useState([]);
  const [blockNews, setBlockNews] = useState(true);

  useEffect(() => {
    axios
    .get('https://api.chemodanchik.online/news')
    .then(response => { setNews(response.data.sort(function(a, b){
      var aa = a.date.split('.').reverse().join(),
          bb = b.date.split('.').reverse().join();
      return aa < bb ? 1 : (aa > bb ? -1 : 0);
  })) });

    document.title = '#ChemoBlog';

  }, []);

  //console.log(news);

  return (
    <>
      <Navigation blockNews={blockNews}/>
        <>
          <div className="news-page__page">
            <div className="news-page">
              <div className="news-page__blocks">
                {news.map(n => (
                  <NewsItem {...n}/>
                ))}
              </div>
            </div>
          </div>
        </> 
      <Form />
      <Footer />
    </>
  )
}

export default NewsPage;