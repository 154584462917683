import { BrowserRouter } from 'react-router-dom';
import Navigate from './routing/Navigate';

import { routes } from './routing/MainRoutes';

import './style/animated.css';
import './style/bootstrap-datapicker.min.css';
import './style/bootstrap.css';
import './style/icomoon.css';
import './App.css';
import {useEffect} from "react";

function App() {

    useEffect(() => {
        console.log(window.location.pathname)
        if (window.location.pathname  !== "/") {
            document.getElementById("searchContainer").style.display = "none";
        }
    }, [])

  return (
    <>
      <BrowserRouter>
        <div>
          <Navigate {...{ routes }} />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
