import React from 'react';

import './media.css';

const Media = () => {

  return (
    <>
      <section className="media">
        <div className="media__block">
          <h3>Не пропустіть свою мандрівку!</h3>
          <p>Підпишіться на зручну для себе соц.мережу і дізнавайтеся першими про гарячі тури, акції та новинки.</p>
          <div className="media__links">
            <a className="media__link-block" rel="noreferrer" target="_blank" href="https://www.instagram.com/chemodanchik_kiev/"><i className="cursor fab fa-instagram"></i></a>
            <a className="media__link-block" rel="noreferrer" target="_blank" href="https://www.facebook.com/chemodanchik.kiev/"><i className="fab fa-facebook-f"></i></a>
            <a className="media__link-block" rel="noreferrer" target="_blank" href="https://invite.viber.com/?g2=AQBzF3HAGo6whEmnEUU7qPkoez6djRaSLwZjsPX0KuDNgjLqZ3kMoMRNL94k6JIk"><i className="fab fa-viber"></i></a>
            <a className="media__link-block" rel="noreferrer" target="_blank" href="https://t.me/chemodanchikkiev"><i className="fab fa-telegram-plane"></i></a>
            <a className="media__link-block" rel="noreferrer" target="_blank" href="https://g.page/chemodanchik-kiev/review?rc"><i className="fab fa-google"></i></a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Media;
