import React, { useState, useEffect } from 'react'
import './podborka-page.css'
import axios from 'axios';
import Footer from '..//Footer/Footer';
import Contact from '..//Contact';
import Navigation from '../Navigation/Navigation'

import Prealoader from '../Prealoader';
import PageNotFound from '../Error/PageNotFound';

const uaKeys = {
  'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
  'є': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'і': 'i',
  'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
  'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
  'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
  'щ': 'shch', 'и': 'y', 'е': 'e', 'ю': 'u', 'я': 'ya',
  'ї': 'i', 'ь': '', 'й': 'yi', '!': 'exc', '?': '@'
}

function transliterate(word) {
  return word.replace(/ /g, '_').split("").map((letter) => {
      const lowLetter = letter.toLowerCase();
      const en = uaKeys[lowLetter] ?? letter;
      return lowLetter === letter ? en : en.toUpperCase();
  }).join("");
}

const PodborkaPage = ( currentActive ) => {

  const [podborka, setPodborka] = useState(null);
  const [backgroundForm, setBackgroundForm] = useState('blue');

  console.log(currentActive)

  const { match } = currentActive;
  const enDescription = match.params.description;
  console.log(enDescription)

  useEffect(() => {
    axios
    .get('https://api.chemodanchik.online/collections')
    .then(response => {
      let exist = false; 
      response.data.forEach(p => {
        console.log(`${transliterate(p.description)}__`, `${enDescription}__`)
      if ( `${transliterate(p.description)}__` === enDescription ) {
        exist = true;
        setPodborka(p);
      }
    }) 
    if (exist === false) {
      setPodborka(false);
    }
  });
  }, []);

  //console.log(currentActive)
  

  if (podborka == null) {
    return (
      <Prealoader/>
    )
  } else if (podborka === false) {
    return (
      <PageNotFound/>
    )
  } else {
    return (
      <>
        {
        Object.keys(podborka).length !== 0 && (
        <>
          <Navigation />
            <div 
              className="podborka-main-page" 
              //style = {{ backgroundImage: `url('${podborka.img}')` }}
            >
              <img 
                src={podborka.img}
                alt=''
                className="podborka-main-photo"
              />
              <div className="podborka-page_overlay"></div>
              <h1>{podborka.description}</h1>
            </div>
            <div className="podborka-page__info">
              <div className="podborka-page__info-block">
                <p>дата</p>
                <h3>{podborka.date}</h3>
              </div>
              <div className="podborka-page__info-block">
                <p>ціна</p>
                <h3>{podborka.price}</h3>
              </div>
              <div className="podborka-page__info-block">
                <a href="#contact" className="podborka-page__btn">Надіслати заявку</a>
              </div>
            </div>
            { podborka.content ? (
              <div className="podborka-page__content">
                <h3>{podborka.title}</h3>
                <p className="content-info">{podborka.content}</p>
              </div>
            ) : (
              <div className="podborka-page__content">
                <h3>{podborka.title}</h3>
                <ul>
                  {podborka.list.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
                <p>{podborka.text}</p>
              </div>
            )}
        <Contact 
          backgroundForm={backgroundForm}
        />
        <Footer />
      </>
      )}
      </>
    )
  }
}

export default PodborkaPage;
