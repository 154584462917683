import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import logo from '..//..//images/logoo.png';

const links = [
  {
    name: "Головна",
    href: "/",
    id: 1,
  },
  {
    name: "Добірки",
    href: "/#podborki",
    id: 2,
  },
  {
    name: "Блог",
    href: "/news",
    id: 3,
  },
  {
    name: "Про нас",
    href: "/#about",
    id: 4,
  },
  {
    name: "Контакти",
    href: "/#contact",
    id: 5,
  },
]

const Navigation = ({blockNews = false}) => {

  const [burger, setBurger] = useState(false);

  const navLink = sessionStorage.getItem('link');
  console.log(navLink);

  let className = 'header__burger';
  let mobMenu = 'header__menu';
  if (burger) {
    className += ' active';
    mobMenu += ' active-menu';
  };

  useEffect(() => {
    let nav = $('#nav');

    if ( !blockNews ) {
      $(window).on("scroll", function() {
        $(nav).toggleClass("active-nav", $(this).scrollTop() > $(nav).height());
      });
    }
  })

  return (
    <>
      <nav id="nav" className={ blockNews ? 'header__main_menu active-nav' : 'header__main_menu header-menu-color' }>
        <a href="/" className="logo-text main-logo">
          <img
            src={logo}
            alt=""
            className="logo"
          />
          {/*<span>Chemodanchik<em>.</em></span>*/}
        </a>
        <div
          className={className}
          onClick={() => { setBurger(!burger)}}
        >
          <span></span>
        </div>
        <div
          className={mobMenu}
        >
          <ul className="header__list" onClick={() => { setBurger(false)}}>
            { links.map( link => (
              <li key={link.id} className="header__item">
                <a
                  href={link.href}
                  className={navLink === link.name ? "logo-text active-link hover-link" : "hover-link logo-text"}
                  onClick={() => {sessionStorage.setItem('link', `${link.name}`)}}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navigation;
