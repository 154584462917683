import React from 'react';

import team1 from '../../images/anna.jpg';
import team2 from '../../images/manager.png';

import './team.css';

const Team = ({ mobile }) => {

  return (
    <section 
      className={ mobile ?  "block-team mobile-team" : "block-team desktop-team" }
    >
      <h1>Наша команда</h1>
      <div className="employee-blocks">
        <div className="employee">
          <img
            alt=""
            className="employee__photo"
            src={team1}
          />
          <h3>Анна Третяк</h3>
          <p>Керуюча турагентством</p>
        </div>
        <div className="employee">
          <img
            alt=""
            className="employee__photo"
            src={team2}
          />
          <h3>Вікторія Лясківська</h3>
          <p>Менеджер з туризму</p>
        </div>
      </div>
    </section>
  )
}

export default Team;
