import React, { useState, useEffect } from 'react';
import '../PodborkaPage/podborka-page.css'
import axios from 'axios';
import Footer from '..//Footer/Footer';
import Contact from '..//Contact';
import Navigation from '../Navigation/Navigation'
import Linkify from 'react-linkify';

import Prealoader from '../Prealoader';
import PageNotFound from '../Error/PageNotFound';

const uaKeys = {
  'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
  'є': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'і': 'i',
  'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
  'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
  'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
  'щ': 'shch', 'и': 'y', 'е': 'e', 'ю': 'u', 'я': 'ya',
  'ї': 'i', 'ь': '', 'й': 'yi', '!': 'exc', '?': '@'
}

function transliterate(word) {
  return word.replace(/ /g, '_').split("").map((letter) => {
      const lowLetter = letter.toLowerCase();
      const en = uaKeys[lowLetter] ?? letter;
      return lowLetter === letter ? en : en.toUpperCase();
  }).join("");
}

const News = ({match}) => {

  const [news, setNews] = useState(null);
  const [backgroundForm, setBackgroundForm] = useState('blue');

  const enDescription = match.params.title;
  console.log(enDescription)

  useEffect(() => {
    axios
    .get('https://api.chemodanchik.online/news')
    .then(response => {
      let exist = false; 
      response.data.forEach(p => {
        console.log(`${transliterate(p.title)}__`, `${enDescription}__`)
      if ( `${transliterate(p.title)}__` === enDescription ) {
        exist = true;
        setNews(p);
      }
    }) 
    if (exist === false) {
      setNews(false);
    }
  });
  }, []);

  //console.log(currentActive)
  console.log(news);

  if (news == null) {
    return (
      <Prealoader/>
    )
  } else if (news === false) {
    return (
      <PageNotFound/>
    )
  } else {
    return (
      <>
        {
        Object.keys(news).length !== 0 && (
        <>
          <Navigation />
          <Linkify>
            <div 
              className="podborka-main-page" 
              //style = {{ backgroundImage: `url('${news.img}')` }}
            >
              <img 
                src={news.img}
                alt=''
                className="podborka-main-photo"
              />
              <div className="podborka-page_overlay"></div>
              <h1>{news.title}</h1>
            </div>
            <div className="podborka-page__info">
              <div className="podborka-page__info-block podborka-page__info-block-news">
                <p>дата</p>
                <h3>{news.date}</h3>
              </div>
              <div className="podborka-page__info-block">
                <a href="#contact" className="podborka-page__btn">Надіслати заявку</a>
              </div>
            </div>
            <div className="podborka-page__content podborka-page__content-news">
              <h3>{news.title}</h3>
              <p className="content-info content-info-news">{news.description}</p>
            </div>
            </Linkify>
        <Contact 
          backgroundForm={backgroundForm}
        />
        <Footer />
      </>
      )}
      </>
    )
}}

export default News;
