import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';

const uaKeys = {
  'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
  'є': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'і': 'i',
  'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
  'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
  'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
  'щ': 'shch', 'и': 'y', 'е': 'e', 'ю': 'u', 'я': 'ya',
  'ї': 'i', 'ь': '', 'й': 'yi', '!': 'exc', '?': '@'
}

function transliterate(word) {
  return word.replace(/ /g, '_').split("").map((letter) => {
      const lowLetter = letter.toLowerCase();
      const en = uaKeys[lowLetter] ?? letter;
      return lowLetter === letter ? en : en.toUpperCase();
  }).join("");
}

const NewsList = ({
  title,
  description,
  date,
  img,
}) => {

  const [fullText, setFullText] = useState(true);
  const [ readMoreBtn, setReadMoreBtn ] = useState(false);

  useEffect(() => {
    //console.log(description.length);

    if ( description.length < 250 ) {
      setReadMoreBtn(true)
    } else {
        return;
    }
    
  }, [])

  return (
    <>
    <Linkify>
      <div 
        className="news-page__block"
        onClick={() => window.open(`/news/${transliterate(title)}__`)}
      >
        <div className="news-page__content">
        <div
            className="news-page__img"
            //style={{backgroundImage: `url("${img}")` }}
          >
            <img 
              src={img}
              alt=''
              className="news-page__photo"
            />
            <div className="news-page__overlay"></div>
            <h2>{title}</h2>
            <p>{date}</p>
          </div>
            <p 
              id='text' 
              className={ fullText ? "news-page__text" : "news-page__text full-text"}
              
            >
              { description }
            </p>
          <button
            className={ fullText ? 'read-more' : 'read-more margin-btn' }
            //onClick={() => {setFullText(!fullText)}}
            style={ readMoreBtn ? { visibility: 'hidden' } : {} }
            onClick={() => window.open(`/news/${transliterate(title)}__`)}
          >
            {fullText ? 'Читати далі...' : 'Менше'}
          </button>
        </div>
      </div>
      </Linkify>
    </>
  )
}

export default NewsList;
