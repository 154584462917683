import React, { useState } from 'react'

import Prealoader from './components/Prealoader.jsx';
import Header from './components/Header';
import Contact from './components/Contact';
import Footer from './components/Footer/Footer';
import Podborki from './components/Podborki/Podborki';
import Media from './components/Media/Media.jsx';
import About from './components/About/About.jsx';
import Form from './components/Form/Form.jsx';
import Testimonials from './components/Testimonials/Testimonials.jsx';
import Team from './components/Team/Team'


const Page = () => {
  const [loaded, setLoaded] = useState(false);

    return (
        <>
        <Prealoader loaded={loaded}/>
        <Header setLoaded={setLoaded}/>
        <Form />
        <Podborki/>
        <Contact />
        <Media />
        <div id="about" >
          <Team />
          <About />
        </div>
        <Testimonials />
        <Footer />
        </>
    )
}

export default Page;