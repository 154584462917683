import React from 'react';
import loader from '../images/logo_new.png'
import '..//style/preloader.css';

const Prealoader = ( {loaded} ) => {
  console.log(loaded);
    return (
        <>
        <div
          className="preloader"
          style={ loaded ? {display: 'none'} : {display: 'block'}}
        >
          <div className="preloader__title">
            <img 
              src={loader}
              alt=''
              className='loader_img'
            />
          </div>
        </div>
        </>
    )
}

export default Prealoader;