import React, {useEffect} from 'react';

import './testimonials.css';

const Testimonials = () => {

  useEffect(() => {
    var  testim = document.getElementById("testim"),
    testimDots = Array.prototype.slice.call(document.getElementById("testim-dots").children),
    testimContent = Array.prototype.slice.call(document.getElementById("testim-content").children),
    testimLeftArrow = document.getElementById("left-arrow"),
    testimRightArrow = document.getElementById("right-arrow"),
    testimSpeed = 5500,
    currentSlide = 0,
    currentActive = 0,
    testimTimer,
    touchStartPos,
    touchEndPos,
    touchPosDiff,
    ignoreTouch = 30;
;

window.onload = function() {

    // Testim Script
    function playSlide(slide) {
        for (var k = 0; k < testimDots.length; k++) {
            testimContent[k].classList.remove("active");
            testimContent[k].classList.remove("inactive");
            testimDots[k].classList.remove("active");
        }

        if (slide < 0) {
            slide = currentSlide = testimContent.length-1;
        }

        if (slide > testimContent.length - 1) {
            slide = currentSlide = 0;
        }

        if (currentActive != currentSlide) {
            testimContent[currentActive].classList.add("inactive");            
        }
        testimContent[slide].classList.add("active");
        testimDots[slide].classList.add("active");

        currentActive = currentSlide;
    
        clearTimeout(testimTimer);
        testimTimer = setTimeout(function() {
            playSlide(currentSlide += 1);
        }, testimSpeed)
    }

    testimLeftArrow.addEventListener("click", function() {
        playSlide(currentSlide -= 1);
    })

    testimRightArrow.addEventListener("click", function() {
        playSlide(currentSlide += 1);
    })    

    for (var l = 0; l < testimDots.length; l++) {
        testimDots[l].addEventListener("click", function() {
            playSlide(currentSlide = testimDots.indexOf(this));
        })
    }

    playSlide(currentSlide);

    // keyboard shortcuts
    document.addEventListener("keyup", function(e) {
        switch (e.keyCode) {
            case 37:
                testimLeftArrow.click();
                break;
                
            case 39:
                testimRightArrow.click();
                break;

            case 39:
                testimRightArrow.click();
                break;

            default:
                break;
        }
    })
    
    testim.addEventListener("touchstart", function(e) {
        touchStartPos = e.changedTouches[0].clientX;
    })
  
    testim.addEventListener("touchend", function(e) {
        touchEndPos = e.changedTouches[0].clientX;
      
        touchPosDiff = touchStartPos - touchEndPos;
      
        console.log(touchPosDiff);
        console.log(touchStartPos);  
        console.log(touchEndPos);  

      
        if (touchPosDiff > 0 + ignoreTouch) {
            testimLeftArrow.click();
        } else if (touchPosDiff < 0 - ignoreTouch) {
            testimRightArrow.click();
        } else {
          return;
        }
      
    })
}
  })

  return (
    <>
    <div className="testimonials-container testimonials-container-height">
    <h1 className="testimonials__h1">Останні відгуки</h1>
    <section id="testim" className="testim">
    <div className="testim-cover">
    <div className="wrap">
        <span id="right-arrow" className="arrow right fa fa-chevron-right"></span>
        <span id="left-arrow" className="arrow left fa fa-chevron-left"></span>
        <ul id="testim-dots" className="dots">
            <li className="dot active"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
            <li className="dot"></li>
        </ul>
        <div id="testim-content" className="cont">
	    <div className="active">
                <h2>Олена Сас</h2>
                <p>Щиро дякуємо турагенству "Чемоданчик" і особисто Анні за черговий неймовірний відпочинок, цього разу в Турції, організований, як завжди, на висоті і в дуже короткі терміни!!! Все було неперевершено, враховані всі побажання до відпочинку. Так тримати!!!</p>
            </div>
	    <div>
		<h2>Yana Kovalevskaya</h2>
		<p>Лучший турагент среди всех, с кем имели дело. Индивидуальный подход, решение любых вопросов/проблем, обратная связь 24/7</p>
            </div>     
            <div>
                <h2>gkhaus</h2>
                <p>Якісний відпочинок можливий лише за умови, що ви обрали професійне турагентство. Цього разу нам пощастило співпрацювати з Анною. Ми вражені високим рівнем сприяння і особливо організаційними та комунікаційними здібностями Ані, як менеджера....</p>
            </div>
         
            <div>
                
                <h2>Карина Колесник</h2>
                <p>Берём уже 3й раз путевку у менеджера Анны и всегда всем довольны. Анна всегда все поможет, подскажет , проверит. Очень ответственный менеджер. Последный раз были проблемы с заселением в отеле , но это быстро решилось и Анна выбила нам и номер получше и сделали подарок на годовщину , которую мы как раз поехали праздновать. Конечно же будем брать туры в Чемоданчике еще 😁😁😁</p>
            </div>

            <div>
                
                <h2>Oksana Fitina</h2>
                <p>Велике дякуємо за тур, особливо Анні!!!Організовували поїздку перший раз з сім'єю за кордон. Дякуючи Анні ніяких проблем не виникало. Спілкування дуже легке, з подробицями, все вчасно. З трьох запропонованих готелів вибрали один і всі були…</p>
            </div>

            <div>
                
                <h2>Ілона Чеперенко</h2>
                <p>Безмежно вдячна Анні за прекрасний відпочинок у чудовому готелі! Все було на найвищому рівні, приїхали повні сил! Приємно здивував готель, розваги є для всіх, і для дорослих і для діток! Тому щиро рекомендуємо готель Long Beach Resort</p>
            </div>

            <div>
                
                <h2>Тетяна Пономаренко</h2>
                <p>Дякуємо менеджеру Ані за наш відпочинок. Завжди на зв'язку,  підтримує порадами і переживає за кожного клієнта! Професіонал своєї справи. Залишились задоволеними відпочинком і роботою цього агенства. Рекомендую всім!!!</p>
            </div>

            <div>
                
                <h2>Anna Verhai</h2>
                <p>Обратилась к Анне по рекомендации многих моих друзей и очень этому рада)
Отличная организация, сам отдых очень понравился и все соответствовало ожиданиям.
Очень приятно, когда люди так подходят к своему делу.</p>
            </div>

            <div>
                
                <h2>Ирина Резниченко</h2>
                <p>Спасибо большое за отдых. Как всегда Вы на высоте🏝🏖🏜</p>
            </div>

            <div>
                
                <h2>Марина Клунко</h2>
                <p>Спасибо огромное за быстро подобранный тур, по очень приятным ценам🔥
Возник форс-мажор по замене туриста буквально за 3 дня до вылета, Анна в тот же день все быстренько поменяла, оформила новые документы, чем сильно помогла нам…</p>
            </div>

            <div>
                <h2>Tanya Bear</h2>
                <p>В первый раз обратилась в тур.агентство "Чемоданчик" по рекламе в Facebook и ни разу не пожалела. Опытный и ответственный менеджер Анна подробно расспросила о запросах, пожеланиях, ценовой политике. Быстро подобрала 3 варианта, я выбрала…</p>
            </div>

            <div>
                <h2>Олена Буката</h2>
                <p>Дуже дякуємо за чудовий відпочинок Турагенству "Чемоданчик", а особливо Анюті Третяк. Ми літали з маленькою дитиною, був підібраний шикарний готель, з дитячим харчуванням за мега круту ціну. Аня завжди на зв'язку, допомагає, відповідає на всі питання. Так тримати 😎</p>
            </div>

            <div>
                <h2>FISH TV</h2>
                <p>Щиро вдячний агентству "Чемоданчик ",за гарно підібраний тур ,раджу всім...
Особлива подяка Анні і Вікторії ,по перше професіонали ,мають особливий підхід до кожного,і просто гарні люди😊
Дуже дякуєм...</p>
            </div>

            <div>
                <h2>Елена П</h2>
                <p>Мастера своего дела!
Подобрали лучший вариант отдыха.
Спасибо большое Анне!</p>
            </div>

            <div>
                <h2>Яна Бондаренко</h2>
                <p>Вдячна, турагенту Аннi за вдалий вiдпочинок!!! Всiм рекомендую користуватись послугамт саме цього агентства!!!</p>
            </div>

            <div>
                <h2>Бусинка Бисеринка</h2>
                <p>Хочу поблагодарить  Туристическое агенство "Чемоданчик" и менеджера Аню за отлично подобранный тур.  Она очень позитивный и светлый человек, терпеливо объясняющая все  варианты, нюансы, тонкости выбора отеля. Отдых получился легкий, веселый…</p>
            </div>

            <div>
                <h2>Yuliya Bezruk</h2>
                <p>Благодарим менеджера Anna Tretiak за отзывчивость и готовность найти тур, который соответствует всем нашим требованиям. Мы доверяем свой отдых вам, потому что вы не подведете - 1000%…</p>
            </div>

            <div>
                <h2>Анна Брусенцева</h2>
                <p>Прекрасное туристическое агенство. Покупали с мужем тур в Египет. Красивый и уютный офис. Милые сувениры из разных стран. Очень вежливая и внимательная менеджер Анна. Подобрала отличный отель. Написала администрации отеля с просьбой чтобы…</p>
            </div>
        </div>
    </div>
  </div>
  <div className="btns-testimonials">
    <a className="btn btn-testimonial" target="_blanc" href="https://www.google.com/maps/place/%D0%A2%D1%83%D1%80%D0%B8%D1%81%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5+%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%81%D1%82%D0%B2%D0%BE+%22%D0%A7%D0%B5%D0%BC%D0%BE%D0%B4%D0%B0%D0%BD%D1%87%D0%B8%D0%BA%22/@50.4941532,30.5218629,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4d10a83a848e5:0x274446399288c764!8m2!3d50.4941532!4d30.5240516"><i className="fab size fa-google"></i>Всі відгуки на Google</a>
    <a className="btn btn-testimonial" target="_blanc" href="https://www.facebook.com/pg/chemodanchik.kiev/reviews/"><i className="fab size icon-facebook"></i>Всі відгуки на Facebook</a>
  </div>
</section>

    </div>

    </>
  )
}

export default Testimonials;
